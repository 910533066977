import { IconClose } from '@moar/svg';
import { Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';

/* eslint-disable-next-line */
export interface BannerProps {
    children: JSX.Element | JSX.Element[];
}

export function Banner(props: BannerProps) {
    const [open, setOpen] = useState(true);
    const { children } = props;

    return (
        <Transition
            show={open}
            as={Fragment}
            enter="transform transition ease-in-out duration-450 opacity-0"
            enterFrom="-translate-y-full"
            enterTo="translate-y-0 opacity-100"
            leave="transform transition ease-in-out duration-450"
            leaveFrom="translate-y-0"
            leaveTo="-translate-y-full"
        >
            <div className="fixed z-40 left-[50%] ml-[-47.5%] w-[95%] md:ml-0 md:left-0 md:w-full mt-3 bg-white rounded-sm md:mt-0 md:rounded-none">
                <div className="max-w-5xl p-3 mx-auto sm:px-6 lg:px-8">
                    <div className="flex items-start justify-between md:items-center md:justify-center">
                        <div className="text-center">{children}</div>

                        <button
                            aria-label="Dismiss PWA banner"
                            tabIndex={0}
                            type="button"
                            className="flex p-2 pt-1 ml-4 md:p-2 md:ml-10 focus:outline-none focus:ring-2 focus:ring-white group"
                            onClick={() => setOpen(false)}
                        >
                            <span className=" w-[12px] h-[12px]">
                                <IconClose />
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </Transition>
    );
}

export default Banner;
